@use "mixins" as *
.pagination
  @include flex
  background: var(--body_bg)
  position: sticky
  bottom: var(--menuHeight)
  padding: 8px 0 12px

.dropdown
  @include flex
  margin-right: auto

  select
    width: 60px
    height: 30px
    margin-left: 8px
    background: var(--ddl_buttonBg)
    box-shadow: inset 0 1px var(--ddl_buttonShadow)

.pageField input
  width: 52px
  height: 30px
  background: var(--pagination_fieldBg)
  margin-right: 6px
  text-align: center
  &:disabled
    opacity: 0.5

.prevBtn,
.nextBtn
  @include flex
  width: 30px
  min-width: 30px
  height: 30px
  background: var(--account_tabActiveBg)
  &:active
    background: var(--account_particularColor)
  &:before
    content: ""
    width: 10px
    height: 5px
    mask-image: var(--icon-arrow)
    background-color: var(--button_color)
    transform: rotate(90deg)
    transform-origin: 45%
    margin-top: -2px
    margin-left: 2px

.prevBtn
  margin-right: 1px
  border-radius: var(--base_borderRadius) 0 0 var(--base_borderRadius)
  margin-left: 8px

.nextBtn
  border-radius: 0 var(--base_borderRadius) var(--base_borderRadius) 0
  &:before
    transform: rotate(-90deg)
    margin-top: 0
