@use "mixins" as *
.notVerifiedTxt
  background: rgba(var(--particularColor2), 0.8)
  box-shadow: 0 0 6px rgba(#000, 0.3)
  position: absolute
  top: 44%
  left: 50%
  transform: translate(-50%, -50%)
  color: #fff
  min-height: 50px
  padding: 0 20px
  width: 80%
  max-width: 300px
  line-height: 20px
  z-index: 1
  border-radius: 6px
  @include flex
  justify-content: center
  text-align: center
